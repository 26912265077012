/* ============ header top style =========== */
.header__top {
    padding: 10px 5px;
    background: #fff;
  }
  
  .bordered-section {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    padding: 10px;
    border: 2px solid #000; /* Black border */
    border-radius: 8px; /* Rounded border */
    font-size: 1rem; /* Unified font size */
    color: #000; /* Unified text color */
    background: #fff; /* Background color */
  }
  
  /* Styling for the Need Help section */
  .header__top__left span,
  .header__top__right-link {
    font-size: 1rem; /* Ensure both sections have the same font size */
    color: #000;
    text-decoration: none;
  }
  
  .header__top__help i {
    background: #fff;
    color: #000;
    padding: 2.5px 5px;
    border-radius: 100px;
    font-size: 0.75rem;
  }
  
  /* Styling for the Request a Call button */
  .header__top__right-link {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    color: #000;
  }
  
  .header__top__right-link:hover {
    color: #000;
  }
  
  
  /* ============ header middle style =========== */
  .header__middle {
    padding: 20px 0px;
    background-color: #000;
  }
  
  .logo h1 a span {
    font-size: 1.5rem;
  }
  
  .logo h1 a i {
    font-size: 2.2rem;
  }
  
  .logo h1 a {
    text-decoration: none;
    color: #000;
    font-weight: 700;
  }
  
  .logo h1 {
    line-height: 1.5rem;
  }
  
  .header__location span i {
    font-size: 2.2rem;
    color: #fff;
  }
  
  .header__location-content h4 {
    margin-top: 0; /* Removed unnecessary margin */
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
  }
  
  .header__location-content h6 {
    color: #fff;
    font-weight: 600;
  }
  
  .header__btn {
    background: #fff !important;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  
  .header__btn a {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    text-decoration: none;
    color: #000;
    justify-content: end;
    font-size: 0.9rem;
  }
  
  .header__btn a:hover {
    color: #000;
  }
  
  
  
  /* ================ main navbar styles =========== */
  
  .main__navbar {
    padding: 15px 0px;
    background: #000;
  }
  
  .mobile__menu i {
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
  }
  
  .mobile__menu {
    display: none;
  }
  
  .menu {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
  }
  
  .nav__item {
    color: #fff;
    text-decoration: none;
    transition: 0.3s;
  }
  
  .nav__item:hover {
    color: #f9a826;
  }
  
  .nav__active {
    color: #f9a826;
  }
  
  @media only screen and (max-width: 768px) {
    .header__top,
    .header__middle,
    .nav__right {
      display: none;
    }
  
    .mobile__menu {
      display: block;
    }
  
    .navigation {
      background: rgb(0, 13, 107, 0.6);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      display: none;
    }
  
    .menu {
      width: 250px;
      height: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 2rem;
      justify-content: center;
    }
  
    .menu a {
      color: #000d6b;
      font-weight: 600;
      font-size: 0.9rem;
    }
  
    .menu__active {
      display: block;
    }
  }
  