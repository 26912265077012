.car__item {
    border: 1px solid #000;
    padding: 20px;
    border-radius: 5px;
  }
  
  .car__item-info span i {
    color: #000;
    font-size: 2rem;
  }
  
  .car__item-btn {
    border-radius: 0;
    border: none;
    outline: none;
    background: #000;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .car__item-btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .car__item-btn a:hover {
    color: #fff;
  }
  .car__btn-details {
    background: #f9a826;
    border-radius: 5px 5px 5px 5px;
    display: inline;
    align-items: center;
    justify-content: center;
  }
  
  .car__btn-rent {
    border-radius: 4px 0px 0px 4px;
  }
  
  .car__item-content h4 {
    font-size: 1.8rem;
    justify-items: center;
    align-items: center;
  }
  
  .car__item-content h6 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  @media only screen and (max-width: 992px) {
    .car__item h4 {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  
    .car__item h6 {
      font-size: 1rem;
      margin-top: 10px;
    }
  
    .car__item-info {
      flex-wrap: wrap;
    }
  
    .car__item-info span {
      font-size: 2rem;
      justify-content: end;
      align-items: end;
    }
  
    .car__item-btn {
      padding: 5px 10px;
      font-size: 0.9rem;
      justify-content: end;
      align-items: end;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .car__item h4 {
      font-size: 1rem;
    }
  
    .car__item h6 {
      font-size: 0.9rem;
    }
  
    .car__item-info span {
      font-size: 0.7rem;
    }
  
    .car__item-info span i {
      font-size: 0.9rem;
    }
  
    .car__item-btn {
      font-size: 0.8rem;
    }
  }
  
