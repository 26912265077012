.car__img-slideshow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .car__img-slideshow img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .car__img-slideshow button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }
  
  .car__img-slideshow button svg {
    width: 20px;
    height: 20px;
  }
  
  .car__img-slideshow button:hover {
    background-color: #f9a826;
  }
  
  .car__img-slideshow button:first-of-type {
    left: 5px;
  }
  
  .car__img-slideshow button:last-of-type {
    right: 5px;
  }
  
  .slide-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }