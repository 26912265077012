/* privacypolicy.css */
.privacy-policy-content {
    padding: 25px;
    max-width: 750px;
    margin: 0 auto;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .privacy-policy-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-content p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  @media only screen and (max-width: 992px) {
      .about__page-content h2 {
        font-size: 1.5rem;
      }
    }
    
    @media only screen and (max-width: 767px) {
      .about__page-img {
        display: none;
      }
    }
    