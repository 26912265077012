.booking__form {
    width: 47.5%;
  }
  
  .booking__form input {
    padding: 8px 15px;
    border: 1px solid #7c8a972a;
    color: #7c8a97;
    width: 100%;
    
  }
  
  .booking__form select,
  .textarea {
    width: 100%;
    padding: 10px 15px;
    color: #7c8a97;
    border: 1px solid #7c8a972a;
    border-radius: 4px;
    
  }
  
  .time__picker::before {
    content: "Journey Time";
    color: #7c8a97;
    margin-right: 0.5rem;
  }
  
  .textarea:focus,
  .booking__form input:focus,
  .booking__form select:focus {
    outline: none;
    
  }
  
  @media only screen and (max-width: 768px) {
    .booking__form {
      width: 100%;
    }
  
    .booking__form input,
    .textarea,
    .booking__form select {
      font-size: 0.8rem;
      
    }
  }
  